import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["selected"]
const _hoisted_2 = ["value", "selected", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("fieldset", null, [
    _createElementVNode("select", {
      style: _normalizeStyle({
                outline: 0,
                border: 0,
                minWidth: `100%`,
                height: `40px`,
                marginTop: `-3px`,
                background: _ctx.backgroundColor ? _ctx.backgroundColor : ``,
            }),
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectValue && _ctx.selectValue(...args)))
    }, [
      (_ctx.needUnselect)
        ? (_openBlock(), _createElementBlock("option", {
            key: 0,
            class: "disabled",
            disabled: "",
            selected: _ctx.Util.isBlank(_ctx.defaultOption)
          }, " 選択してください ", 8, _hoisted_1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("option", {
          value: option,
          key: `${_ctx.prefixNum}${index}`,
          selected: option == _ctx.defaultOption,
          disabled: _ctx.isDisabled ? true : false
        }, _toDisplayString(option), 9, _hoisted_2))
      }), 128))
    ], 36)
  ]))
}